// firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc, getDocs, connectFirestoreEmulator } from "firebase/firestore";
import { getAuth, connectAuthEmulator, onAuthStateChanged, GoogleAuthProvider, signInWithPopup, signOut } from "firebase/auth"; // connectAuthEmulator を追加
import { getStorage } from "firebase/storage";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import store from './store';

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID
};

const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp);
const formDataCollection = collection(db, "review");
const auth = getAuth(firebaseApp);
const storage = getStorage(firebaseApp);
const functions = getFunctions(firebaseApp);

// ローカルで実行している場合、エミュレーターに接続
// if (window.location.hostname === "localhost") {
//   connectFirestoreEmulator(db, 'localhost', 8080);
//   connectFunctionsEmulator(functions, 'localhost', 5001);
//   connectAuthEmulator(auth, 'http://localhost:9099'); // Authエミュレーターに接続
// }

// 認証状態の監視
onAuthStateChanged(auth, (user) => {
  if (user) {
    store.commit('setUserUID', user.uid);
  } else {
    store.commit('setUserUID', null);
  }
  store.commit('setLoggedIn', !!user);
});

// Google認証プロバイダの設定
const provider = new GoogleAuthProvider();

// Googleでログインする関数
const signInWithGoogle = async () => {
  try {
    const result = await signInWithPopup(auth, provider);
    const user = result.user;
    store.commit('setUserUID', user.uid);
    store.commit('setLoggedIn', true);
  } catch (error) {
    console.error("Googleログインエラー:", error);
  }
};

// サインアウト関数
const signOutUser = async () => {
  try {
    await signOut(auth);
    store.commit('setUserUID', null);
    store.commit('setLoggedIn', false);
  } catch (error) {
    console.error("サインアウトエラー:", error);
  }
};

// 現在のユーザー情報を取得する関数
const getUserInfo = () => {
  return auth.currentUser;
};


export {
  db,
  formDataCollection,
  getDocs,
  collection,
  auth,
  addDoc,
  getUserInfo,
  storage,
  signInWithGoogle,
  signOutUser
};
